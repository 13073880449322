@font-face {
	font-family: 'Lovelo';
	src: url('./Lovelo-Black.woff2') format('woff2');
}


html, body, .App {
	position: relative;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100vh;
}

.App {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font: 10vw 'Lovelo', sans-serif;
	color: transparent;
	-webkit-text-stroke: 1px white;
	outline: none;
}

.sentence {
	width: 100%;
	text-align: center;
	position: relative;
}

.sentence::after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	width: 0%;
	height: 100%;
	background: white;
	transition: .15s ease;
}

.sentence.transition::after {
	width: 100%;
	left: 0;
}